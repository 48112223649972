@import url('http://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext');

:root {
    --green: #2AF598;
    --dark-green: #19B95C;
    --red: #FF0000;
    --blue: #1a80c7;
    --dark-blue: #061A40;
    --white: white;
    --off-white: #EAEAEA;

    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 300;
    color: var(--off-white);
}

html, body {
    margin: 0;
    background: var(--dark-blue);
    box-sizing: border-box;
}

* {
    box-sizing: inherit;
}
