@import url(http://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext);
:root {
    --green: #2AF598;
    --dark-green: #19B95C;
    --red: #FF0000;
    --blue: #1a80c7;
    --dark-blue: #061A40;
    --white: white;
    --off-white: #EAEAEA;

    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 300;
    color: #EAEAEA;
    color: var(--off-white);
}

html, body {
    margin: 0;
    background: #061A40;
    background: var(--dark-blue);
    box-sizing: border-box;
}

* {
    box-sizing: inherit;
}

.Books_bookDetails__3h8oJ {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Books_bookDetails__3h8oJ > * {
    margin: 1rem 1rem 0 1rem;
}

.Books_bookDetails__3h8oJ > h1,
.Books_bookDetails__3h8oJ > p {
    text-align: center;
}

.Books_checkInOutButton__3UDtY {
    max-width: 30rem;
    display: block;
    text-align: center;
    text-transform: uppercase;
    border: solid 2px var(--dark-green);
    background-color: var(--dark-green);
    border-radius: 3px;
    font-size: 20px;
    padding: 18px 30px;
    cursor: pointer;
}

.Books_checkInOutButton__3UDtY:hover {
    background-color: white;
    color: var(--dark-green);
    text-decoration: underline;
}
.BookList_heading__1pzAq {
  color: var(--white);
  margin: 1rem 1rem 0 1rem;
  text-transform: uppercase;
}

.BookList_bookList__3G3q5 {
  list-style: none;
  margin: 1rem;
  padding: 0;
}

.BookList_listItem__3YjdW {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: var(--off-white) solid thin;
}

.BookList_listItem__3YjdW > img {
  margin-right: 1rem;
}

.BookList_checkedOutIcon__2rWbe {
  height: 1rem;
  color: var(--red);
}

.BookList_availableIcon__8221C {
  height: 1rem;
  color: var(--green);
}

.BookList_bookLink__3ZSwF {
  color: var(--off-white);
  text-decoration: none;
  margin-left: 0.5rem;
}

.BookList_bookLink__3ZSwF:hover {
  color: var(--blue);
}

.BookList_search__4JNaY {
  margin: 1rem 1rem 0;
  padding: 0.5rem;
  background: transparent;
  color: var(--white);
  border: 1px solid var(--off-white);
  font-size: 1.25rem;
}

.BookList_searchIcon__1GzrW {
  color: var(--white);
  height: 1rem;

  position: relative;
  left: -2.5rem;
}

.BookList_loading__3xUL6 {
  text-align: center;
}

.BulkAddPage_bookTable__1pafu {
    width: 100%;
}
.BulkAddPage_bookTable__1pafu th {
    padding: .5rem;
}

.BulkAddPage_bookTable__1pafu thead input {
    display: block;
    width: 100%;
}
.Header_header__1VCKf {
    background-color: var(--blue);
    padding: .5rem 1rem;
    border-bottom: 3px solid  var(--dark-green);
}

.Header_logo__3_SJs {
    height: 2rem;
}

